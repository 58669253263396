<template>
  <!-- <t-pull-down-refresh
    v-model="refreshing"
    :loading-bar-height="66"
    @refresh="handleRefresh"
  > -->
  <t-list class="list-box">
    <t-cell-group>
      <t-cell
        v-for="item in newsList"
        :key="item.article_id"
        :title="item.title"
        :image="item.cover"
        @click="$router.push('/newsdetail/' + item.article_id)"
      />
    </t-cell-group>
  </t-list>
  <!-- </t-pull-down-refresh> -->
</template>
<script setup>
import { onMounted, ref } from "vue";
import { fetchArticleList } from "../network/API";
import tools from "../utils/tools";

const newsList = ref([]);
var page = 1;
const refreshing = ref(false);
onMounted(() => {
  getArticleList();
  tools.shareInit();
});
function handleRefresh() {
  refreshing.value = true;
  getArticleList();
}
function handleLoadMore() {
  page++;
  refreshing.value = true;
  getArticleList();
}

async function getArticleList() {
  let res = await fetchArticleList({
    page_size: 200,
    page: page,
    code: "axbm",
  });
  refreshing.value = false;
  newsList.value = res;
}
</script>
<style scoped>
.list-box {
  margin: 0;
  padding: 0;
  border-radius: 0;
  min-height: 100vh;
}
</style>
